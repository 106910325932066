.install-guide {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .install-guide h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .install-guide h2 {
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  
  .install-guide ul {
    margin-left: 20px;
  }
  
  .install-guide li {
    margin-bottom: 10px;
  }  